@font-face {
  font-family: "PassionOne";
  font-weight: 400;
  src: url(../assets/PassionOne-Regular.ttf);
}
@font-face {
  font-family: "PassionOne";
  font-weight: 700;
  src: url(../assets/PassionOne-Bold.ttf);
}
@font-face {
  font-family: "PassionOne";
  font-weight: 900;
  src: url(../assets/PassionOne-Black.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Paytone+One&display=swap");
